import React from "react"
import { StoryGroup } from "components"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "Temel İnternet Kavramları",
    url: "https://learnreactui.dev/contents/temel-internet-kavramlari",
    img: getEBooksCoverUrl("012_temel-internet-kavramlari"),
  },
  {
    title: "Fundamental Internet Concepts",
    url: "https://learnreactui.dev/contents/fundemental-internet-concepts",
    img: getEBooksCoverUrl("013_fundemental-internet-concepts"),
  },
]

export const LinkMap = {
  //Concepts
  HTML: "4bb73317eea6",
  HTTP: "4bb73317eea6",
  W3C: "4bb73317eea6",
  "Publishing Web": "4bb73317eea6",
  "Social And Co-Created Web": "4bb73317eea6",
  RIA: "4bb73317eea6",
  WOA: "4bb73317eea6",
  "Social Web": "4bb73317eea6",
  "Semantic & Intelligent Web": "4bb73317eea6",
  MetaData: "4bb73317eea6",
  Vocabulary: "4bb73317eea6",
  Syntax: "4bb73317eea6",
  "schema.org": "4bb73317eea6",
  "opengraphprotocol.org": "4bb73317eea6",
  "Dublin Core Schema": "4bb73317eea6",
  FOAF: "4bb73317eea6",
  SIOC: "4bb73317eea6",
  MicroData: "4bb73317eea6",
  "JSON-LD": "4bb73317eea6",
  RFDa: "4bb73317eea6",
  "The Mobile, Machine": "4bb73317eea6",
  "Object Web": "4bb73317eea6",
  IOT: "4bb73317eea6",
  MQTT: "4bb73317eea6",
  "Sensory-Emotive Web": "4bb73317eea6",

  IETF: "85aa8352f470",
  RFC: "85aa8352f470",

  DNS: "5a06e8039a2",
  iPV4: "5a06e8039a2",
  iPV6: "5a06e8039a2",
  FTP: "5a06e8039a2",
  "A Record": "5a06e8039a2",
  "CName Record": "5a06e8039a2",
  "MX Record": "5a06e8039a2",
  "AAAA Record": "5a06e8039a2",
  "SOA Record": "5a06e8039a2",
  "TXT Record": "5a06e8039a2",
  "NS Record": "5a06e8039a2",
  "SRV Record": "5a06e8039a2",
  "PTR Record": "5a06e8039a2",

  "TCP/IP": "c550cfcb62a5",
  "OSI Model": "c550cfcb62a5",
  TCP: "c550cfcb62a5",
  UDP: "c550cfcb62a5",
  SOAP: "c550cfcb62a5",
  RPC: "c550cfcb62a5",
  REST: "c550cfcb62a5",
  GraphQL: "c550cfcb62a5",
  Webhook: "c550cfcb62a5",
  "Network Katmanı": "c550cfcb62a5",
  "Transport Katmanı": "c550cfcb62a5",
  "App Katmanı": "c550cfcb62a5",

  "Incoming Web Hook": "47b73b386e67",
  "Outgoing Web Hook": "47b73b386e67",

  SMTP: "de5c90f8ff",
  POP3: "de5c90f8ff",
  IMAP: "de5c90f8ff",
  MSA: "de5c90f8ff",
  MUA: "de5c90f8ff",
  "RFC 821": "de5c90f8ff",
  "RFC 5821": "de5c90f8ff",

  "Swamp of POX": "69c6b89da06",
  Resources: "69c6b89da06",
  "HTTP Verbs": "69c6b89da06",
  "HyperMedia Controls": "69c6b89da06",
  HATEOAS: "69c6b89da06",

  URL: "eb71206d1b42",
  "Domain Name": "eb71206d1b42",
  Port: "eb71206d1b42",
  Path: "eb71206d1b42",
  "Query Parameters": "eb71206d1b42",
  Anchor: "eb71206d1b42",

  "Web 3": "f5101a4796cc",

  "HTTP Request/Response": "e3d4cc59d415",
  "Real Time Application": "e3d4cc59d415",
  "Chat Application": "e3d4cc59d415",
  "Game Application": "e3d4cc59d415",
  "HTTP Methods": "e3d4cc59d415",
  "CONNECT,OPTIONS,HEAD,TRACE,GET,POST,PUT,DELETE,PATCH": "e3d4cc59d415",
  "Status Code": "e3d4cc59d415",
  "Mime Types": "e3d4cc59d415",
}

const stories = [
  {
    title: "Web, Internet ve Temel Kavramlar Eğitim İçeriği",
    postId: "eb2fd1f9a4de",
  },
  {
    title: "Web 1.0 →2.0 →3.0 →4.0 →5.0",
    postId: "4bb73317eea6",
  },
  {
    title: "Web 3, Web3.0",
    postId: "f5101a4796cc",
  },
  {
    title: "Internet Ağ Kavramları",
    postId: "c550cfcb62a5",
  },
  {
    title: "RFC Nedir? Neden Önemlidir ?",
    postId: "85aa8352f470",
  },
  {
    title: "DNS Nedir? Neyi Sağlar",
    postId: "5a06e8039a2",
  },
  {
    title: "URL Nedir",
    postId: "eb71206d1b42",
  },
  {
    title: "HTTP Request/Response ve WebSocket",
    postId: "e3d4cc59d415",
  },
  {
    title: "REST Nedir ? (Richardson Maturity Model)",
    postId: "69c6b89da06",
  },
  {
    title: "WebHook Nedir ?",
    postId: "47b73b386e67",
  },
  {
    title: "SMTP Nedir ? Nasıl Çalışır ?",
    postId: "de5c90f8ff",
  },
  {
    title: "gRPC Nedir ? API'lerde Kullanımı",
    postId: "bd40e5f17c78",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Internet Kavramları",
  path: "internet-kavramlari",
}

const InternetConceptsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default InternetConceptsPage
